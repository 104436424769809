<template>
    <div>
        <template v-if="addNewUserBooking">
            <validation-observer v-slot="{ invalid }" v-if="addBooking">
                <h5 class="mb-4">Add Booking</h5>

                <b-alert :show="showError.length" variant="warning">
                    {{ showError }}
                </b-alert>

                <validation-provider
                    v-for="(field, index) in date.fields"
                    v-slot="context"
                    :key="field.id"
                    :name="field.field_label"
                    :rules="`${field.required || field.is_default ? 'required' : ''}`"
                >
                    <div v-if="field.field_type === 'terms'">
                        <b-form-group>
                            <b-form-checkbox
                                v-model="fields[index].value"
                                :placeholder="field.placeholder"
                                :state="getValidationState(context)"
                                v-bind="context.ariaInput">
                                {{ field.field_label }}
                            </b-form-checkbox>
                            {{ field.info }}
                            <b-form-invalid-feedback v-bind="context.ariaMsg">
                                {{ context.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </div>

                    <div v-else-if="field.field_type === 'textfield'">
                        <b-form-group :label="field.field_label">
                            <b-form-textarea
                                v-model="fields[index].value"
                                :placeholder="field.placeholder"
                                :state="getValidationState(context)"
                                v-bind="context.ariaInput">
                                {{ field.info }}
                            </b-form-textarea>
                            <b-form-invalid-feedback v-bind="context.ariaMsg">
                                {{ context.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </div>

                    <div v-else>
                        <b-form-group :label="field.field_label">
                            <b-form-input
                                v-model="fields[index].value"
                                :placeholder="field.placeholder"
                                :state="getValidationState(context)"
                                v-bind="context.ariaInput"
                            />
                            <b-form-invalid-feedback v-bind="context.ariaMsg">
                                {{ context.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </div>

                    <b-form-invalid-feedback v-bind="context.ariaMsg">
                        {{ context.errors[0] }}
                    </b-form-invalid-feedback>
                </validation-provider>
                <h5>Choose giftcards to book</h5>
                <b-button
                    class="m-2 cursor-pointer d-block"
                    :disabled="giftcardsForBooking.length === 1 || giftcard.redeemed_at !== null"
                    :class="{
            'bg-danger text-white': giftcard.redeemed_at !== null,
            'bg-light text-black': giftcard.redeemed_at === null && !giftcardsToBook.includes(giftcard),
            'bg-success text-white': giftcard.redeemed_at === null && giftcardsToBook.includes(giftcard),
          }"
                    @click.prevent="toggleGiftcardToBook(giftcard)"
                    v-for="(giftcard, index) in giftcardsForBooking"
                    :key="giftcard.id"
                >
                    <span>Giftcard #{{ index + 1 }}</span>
                    {{ giftcard.code }} {{ giftcard.redeemed_at ? '(Redeemed)' : '' }}
                </b-button>

                <hr/>

                <div class="clearfix">
                    <div class="pull-right">
                        <b-button variant="primary" :disabled="giftcardsToBook.length === 0 || invalid" @click="book">
                            Book
                        </b-button>
                    </div>
                </div>
            </validation-observer>

            <validation-observer v-else v-slot="{ invalid }">
                <h5 class="mb-4">Verify Giftcard</h5>

                <b-alert :show="showError.length" variant="warning">
                    {{ showError }}
                </b-alert>

                <validation-provider name="Voucher Code" v-slot="context" rules="required">
                    <b-form-group label="Voucher Code" label-for="voucher-code-id">
                        <b-form-input
                            v-model="giftcardCode"
                            id="voucher-code-id"
                            placeholder="e.g AD52893JD93J"
                            :min="0"
                            :state="getValidationState(context)"
                            v-bind="context.ariaInput"
                        />

                        <b-form-invalid-feedback v-bind="context.ariaMsg">
                            {{ context.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <hr/>

                <div class="clearfix">
                    <div class="pull-right">
                        <b-button variant="link" class="mr-3" @click="cancelBookingForm">
                            Cancel
                        </b-button>

                        <b-button variant="primary" :disabled="invalid" @click="verifyGiftcardCode">
                            Verify Booking
                        </b-button>
                    </div>
                </div>
            </validation-observer>
        </template>

        <template v-else>
            <b-row class="mb-2">
                <b-col>
                    <b-dropdown text="Export" size="sm" right class="pull-right">
                        <b-dropdown-item-button v-for="(format, id) in formats" :key="id" @click="exportAs(format)">
                            Export as {{ format.toUpperCase() }}
                        </b-dropdown-item-button>
                    </b-dropdown>
                </b-col>
            </b-row>

            <hr/>
            <div v-if="!date.cancelled" class="clearfix">
                <b-button variant="primary" class="pull-right" @click="cancelBookingForm">
                     Add Booking
                </b-button>
            </div>
        </template>

        <!-- Name	Email	Phone	Code -->
        <b-table v-if="!loadingCancelForm" :items="bookings" :fields="tableFields" :tbody-tr-class="rowClass">
            <template v-slot:cell(cancel)="row">
                <a v-if="!row.item.deleted_at" class="btn btn-sm btn-danger" href="#"
                   @click="loadCancelEvent(row.item.id)"
                >Cancel</a
                >
            </template>
        </b-table>

        <div v-if="loadingCancelForm">
            <hr>
            <b-form-group label="Communicate with">
                <b-form-checkbox-group v-model="communicate">
                    <b-form-checkbox value="sms">SMS</b-form-checkbox>
                    <b-form-checkbox value="email">E-Mail</b-form-checkbox>
                </b-form-checkbox-group>
            </b-form-group>

            <b-form-group label="Message to already booked participants">
                <b-form-textarea v-model="content" placeholder="Enter something..." rows="3" max-rows="6"/>
            </b-form-group>

            <div class="clearfix">
                <div class="pull-right">
                    <b-button variant="link" class="mr-2" @click="closeCommunicationForm">
                        Close
                    </b-button>
                    <b-button variant="danger" @click="cancelEvent">
                        Cancel Booking
                    </b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {booking} from '@/api'
import {getValidationState} from '../pages/page-helpers'
import dayjs from 'dayjs'

export default {
    props: {title: String, date: Object},

    watch: {
        date() {
            this.bookings = this.date.bookings
        },
    },
    data() {
        return {
            formats: ['xls', 'csv'],
            bookings: this.date.bookings,
            giftcardsForBooking: [],
            giftcardsToBook: [],
            addNewUserBooking: false,
            tableFields: [
                {
                    key: 'name',
                    label: 'Name',
                    formatter: (value, key, item) => {
                        return item.fields[0].value + ' ' + item.fields[1].value
                    },
                },
                {
                    key: 'email',
                    label: 'Email',
                    formatter: (value, key, item) => {
                        return item.fields[2].value
                    },
                },
                {
                    key: 'phone',
                    label: 'Phone',
                    formatter: (value, key, item) => {
                        return item.fields[3].value
                    },
                },
                {
                    key: 'code',
                    label: 'Code',
                },
                {
                    key: 'cancel',
                    label: 'Cancel',
                },
            ],
            giftcardCode: '',
            showError: '',
            addBooking: false,
            fields: this.date.fields.map(field => ({
                booking_date_field_id: field.id,
                value: field.field_type === 'terms' ? 0 : '',
            })),
            communicate: ['sms', 'email'],
            content: [
                'Hei! Du har booket deg på',
                `${this.title} den ${dayjs(this.date.starts_at).format('DD-MM-YYYY HH:mm')}.`,
                'Denne dagen er dessverre blitt avlyst.',
                'Ta kontakt med kundeservice for mer informasjon.',
                'E-post: support@youwish.no. Tlf.: 22 33 33 40.',
            ].join(' '),
            loadingCancelForm: false,
            eventId: null
        }
    },
    computed: {
        bookingData() {
            return {
                booking_date_id: this.date.id,
                giftcard_code: this.giftcardCode,
                giftcard_ids: this.giftcardsToBook.map(g => g.id),
                confirmed: true,
                fields: this.fields,
            }
        },
    },
    methods: {
        getValidationState,
        rowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.deleted_at) {
                return 'deleted-booking'
            }
            return 'test'
        },
        toggleGiftcardToBook(giftcard) {
            if (this.giftcardsToBook.find(g => g.id === giftcard.id)) {
                this.giftcardsToBook = this.giftcardsToBook.filter(item => item.id !== giftcard.id)
            } else {
                this.giftcardsToBook.push(giftcard)
            }
        },
        async exportAs(format) {
            const response = await booking.dates.export(this.date.id, format)
            this.$bvToast.toast(`Booking date export opened in new window`, {
                title: 'Booking date exported',
                variant: 'primary',
            })

            window.open(response.data.url)
        },
        cancelBookingForm() {
            this.addNewUserBooking = !this.addNewUserBooking
            this.loadingCancelForm = false
            this.eventId = null
        },
        loadCancelEvent(id) {
            this.eventId = id
            this.loadingCancelForm = true
            this.addNewUserBooking = false
        },
        closeCommunicationForm() {
            this.eventId = null
            this.loadingCancelForm = false
        },
        async cancelEvent() {
            await booking.cancel(this.eventId, this.content, this.communicate)
            this.loadingCancelForm = false
            this.$bvToast.toast(`Booking cancelled for supplier`, {
                title: 'Booking cancelled',
                variant: 'primary',
            })
            this.$emit('booking-added')
        },
        async verifyGiftcardCode() {
            try {
                const res = await booking.dates.verifyGiftcard(this.date.id, this.giftcardCode)

                this.showError = ''
                this.addBooking = true
                this.giftcardsForBooking = res.giftcards
                this.giftcardsToBook = this.giftcardsForBooking.length === 1 ? [this.giftcardsForBooking] : []
            } catch (error) {
                this.showError = 'Invalid Giftcard'
                this.addBooking = false
            }
        },
        async book() {
            try {
                await booking.create(this.bookingData)
                this.$bvToast.toast(`Booking data create for supplier variation`, {
                    title: 'Booking added',
                    variant: 'primary',
                })

                this.giftcardsForBooking = []
                this.giftcardsToBook = []
                this.addNewUserBooking = false
                this.giftcardCode = ''
                this.addBooking = false
                this.fields = this.date.fields.map(field => ({
                    booking_date_field_id: field.id,
                    value: field.field_type === 'terms' ? 0 : null,
                }))

                this.$emit('booking-added')
            } catch (error) {
                const messages = error.errors[Object.keys(error.errors)[0]]

                this.showError = messages[0]
            }
        },
    },
}
</script>
<style lang="scss">
tr.deleted-booking {
    background: red;
    color: white;
      text-decoration: line-through;
}

</style>
