<template>
  <div id="content" class="app-content">
    <h1 class="page-header">
      <span>Supplier: {{ supplier.booking_name }}</span>
      <small> all product variations.</small>
    </h1>

    <b-row>
      <b-col cols="4">
        <b-card no-body class="mb-2" v-for="variation in listing" :key="variation.id">
          <b-card-body role="tab" class="p-0 rounded" :style="{backgroundColor: variation?.template?.color || '#2ECC70'}">
            <b-row class="px-3 py-1">
              <b-col class="py-1">
                <router-link :to="{ name: 'product.variations.edit', params: { variation: variation.id } }"
                             target="_blank"
                             class="pr-2 text-light"
                >
                  <i class="fas fa-external-link-alt"/>
                </router-link>

                <strong class="text-light">{{ variation.variation_name }} ({{ variation.place.city.name }})</strong>
              </b-col>
              <b-col cols="auto" class="px-3 py-1 rounded font-weight-light">
                <strong
                  :class="variation.booking_type === 'date' ? 'text-white' : 'text-black'"
                  v-text="variation.booking_type === 'date' ? 'Enabled' : 'Disabled'"
                ></strong>
              </b-col>
              <b-col class="py-1" cols="auto">
                <button v-b-toggle="`accordion-${variation.id}`" class="btn p-0 m-0" style="line-height: 1">
                  <i class="fa fa-angle-down text-light" />
                </button>
              </b-col>
            </b-row>
          </b-card-body>

          <b-collapse
            :id="`accordion-${variation.id}`"
            :visible="listing.length === 1"
            accordion="products-accordion"
            role="tabpanel"
          >
            <div class="px-3 py-4">
              <template v-if="!variation.template">
                <b-button
                  v-b-modal="`modal-${variation.id}`"
                  size="sm"
                  variant="primary"
                  :disabled="$cannot('supplier.booking')"
                >
                  Set Booking
                </b-button>

                <b-modal
                  :id="`modal-${variation.id}`"
                  :title="variation.variation_name"
                  v-slot="{ ok, cancel }"
                  hide-footer
                  :no-close-on-backdrop="true"
                >
                  <suppliers-booking-settings-form
                    :variation="variation"
                    @created="onTemplateCreated(ok)"
                    @updated="onTemplateUpdated"
                    @cancel="cancel"
                  />
                </b-modal>
              </template>

              <suppliers-booking-settings-form
                v-else
                :variation="variation"
                @updated="onTemplateUpdated"
                @date-submitted="onDateSubmitted"
                @custom-field-added="onCustomFieldAdded"
                @custom-field-removed="onCustomFieldRemoved"
                @cancel="$bvModal.hide(`modal-${variation.id}`)"
                hide-cancel
              />
            </div>
          </b-collapse>
        </b-card>
      </b-col>

      <b-col>
        <b-card title="Dates &amp; Bookings">
          <full-calendar :options="calendarOptions" />
        </b-card>

        <b-modal
          size="lg"
          v-for="date in dates"
          :key="date.id"
          :title-html="getTitle(date)"
          :id="`calendar-date-${date.id}`"
          hide-footer
          :no-close-on-backdrop="true"
        >
          <b-tabs content-class="mt-3" fill justified>
            <b-tab title="Info" :active="date.cancelled">
              <suppliers-booking-settings-date :date="date" @updated="fresh" />
            </b-tab>
            <b-tab title="Bookings" :active="date.confirmed">
              <suppliers-booking-settings-bookings :title="date.variation_name" :date="date" @booking-added="fresh" />
            </b-tab>
            <b-tab title="Settings" :disabled="date.cancelled">
              <suppliers-booking-settings-cancel
                :title="date.variation_name"
                :date="date"
                @cancelled="fresh"
                @close="id => $bvModal.hide(id)"
              />
            </b-tab>
          </b-tabs>
        </b-modal>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import { dates, suppliers } from '@/api'
import SuppliersBookingSettingsForm from './SuppliersBookingSettingsForm.vue'
import SuppliersBookingSettingsDate from './SuppliersBookingSettingsDate.vue'
import SuppliersBookingSettingsCancel from './SuppliersBookingSettingsCancel.vue'
import SuppliersBookingSettingsBookings from './SuppliersBookingSettingsBookings.vue'

export default {
  name: 'SuppliersBookingSettings',
  inject: ['$cannot'],
  components: {
    FullCalendar,
    SuppliersBookingSettingsForm,
    SuppliersBookingSettingsDate,
    SuppliersBookingSettingsCancel,
    SuppliersBookingSettingsBookings,
  },
  async mounted() {
    const response = await suppliers.find(this.$route.params.id, {
      include: ['variations.dates', 'variations.template'].join(','),
    })

    this.supplier = response.data
    this.fresh()
  },
  data() {
    return {
      listing: [],
      supplier: {},
      dates: [],
      calendarOptions: {
        timeZone: 'Europe/Oslo',
        plugins: [dayGridPlugin, listPlugin, timeGridPlugin, interactionPlugin],
        slotLabelFormat: { hour12: false, hour: '2-digit', minute: '2-digit' },
        initialView: 'dayGridMonth',
        firstDay: 1,
        events: [],
        eventDisplay: 'block',
        eventClick: this.handleCalendarEventClick,
        displayEventTime: false,
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay,listYear,listMonth',
        },
      },
    }
  },
  methods: {
    async fresh() {
      const response = await suppliers.find(this.$route.params.id, {
        include: ['variations.dates', 'variations.template'].join(','),
      })
      const bookingDates = await dates.all({
        filter: {
          supplier_id: this.$route.params.id,
        },
      })
      this.dates = bookingDates.data()
      this.listing = response.data.variations

      this.fillCalendarEvents()
    },
    onTemplateCreated(done) {
      this.fresh()
      done()
      this.$bvToast.toast(`A new booking template was created for supplier: "${this.supplier.booking_name}".`, {
        title: 'Booking template created.',
        variant: 'primary',
      })
    },
    onTemplateUpdated() {
      this.fresh()
      this.$bvToast.toast(`Supplier booking template updated.`, {
        title: 'Booking template updated',
        variant: 'primary',
      })
    },
    onDateSubmitted() {
      this.fresh()
      this.$bvToast.toast(`Event published on the date you chose.`, {
        title: 'Event published',
        variant: 'primary',
      })
    },
    onCustomFieldAdded() {
      this.fresh()
      this.$bvToast.toast(`New custom field added.`, {
        title: 'Custom field',
        variant: 'primary',
      })
    },
    onCustomFieldRemoved() {
      this.fresh()
      this.$bvToast.toast(`A custom field was deleted from "${this.supplier.booking_name}"`, {
        title: 'Custom field deleted',
        variant: 'primary',
      })
    },
    fillCalendarEvents() {
      this.calendarOptions.events = this.dates.map(date => {
        const variation = this.listing.find(v => v.id === date.product_variation)

        const start = dayjs(date.starts_at).format('HH:mm')
        const end = dayjs(date.ends_at).format('HH:mm')
        const product = variation.variation_name
        const count = date.bookings.length

        return {
          title: `${start} - ${end} ${product} (${count} of ${date.participants})`,
          start: date.starts_at,
          end: date.ends_at,
          extendedProps: { variation, date },
          backgroundColor: date.cancelled ? '#E84B3C' : date.color ?? '#00C27C',
          classNames: date.cancelled ? ['fc-event-cancelled'] : []
        }
      })
    },
    handleCalendarEventClick(info) {
      if (this.$cannot('supplier.manage')) return

      const { date } = info.event.extendedProps

      this.$bvModal.show(`calendar-date-${date.id}`)
    },
    getTitle(date) {
      const title = `${date.variation_name} (${date.bookings.filter(booking => booking.deleted_at === null).length} of ${date.participants})`
      const variation = this.listing.find(({ id }) => id === date.product_variation)
      const cancelled = '<span class="text-danger">(CANCELLED)</span> '
      if (variation && variation?.place?.city?.name) {
          if (date.cancelled) {
              return cancelled + `${variation.place.city.name} ` + title
          }

        return `${variation.place.city.name} ` + title
      }

      if (date.cancelled) {
          return cancelled + title;
      }

      return title
    },
  },
}
</script>
<style>
.fc-event-cancelled {
    .fc-event-title {
        text-decoration: line-through;
    }
}
</style>
