<template>
  <div>
    <template v-if="!loading">
      <b-button variant="danger" v-if="date.confirmed" @click="loadCancelEvent">
        Cancel Event
      </b-button>
      <p v-else>This date is already cancelled</p>
    </template>

    <div v-if="loading">
      <b-form-group label="Communicate with">
        <b-form-checkbox-group v-model="communicate">
          <b-form-checkbox value="sms">SMS</b-form-checkbox>
          <b-form-checkbox value="email">E-Mail</b-form-checkbox>
        </b-form-checkbox-group>
      </b-form-group>

      <b-form-group label="Message to already booked participants">
        <b-form-textarea v-model="content" placeholder="Enter something..." rows="3" max-rows="6" />
      </b-form-group>

      <hr />

      <div class="clearfix">
        <div class="pull-right">
          <b-button variant="link" class="mr-2" @click="close">
            Close
          </b-button>
          <b-button variant="danger" @click="cancelEvent">
            Cancel Event
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dates } from '@/api'
import dayjs from 'dayjs'

export default {
  props: { title: String, date: Object },
  data() {
    return {
      loading: false,
      communicate: ['sms', 'email'],
      content: [
        'Hei! Du har booket deg på',
        `${this.title} den ${dayjs(this.date.starts_at).format('DD-MM-YYYY HH:mm')}.`,
        'Denne dagen er dessverre blitt avlyst.',
        'Ta kontakt med kundeservice for mer informasjon.',
        'E-post: support@youwish.no. Tel: 22 33 33 40',
      ].join(' '),
    }
  },
  methods: {
    loadCancelEvent() {
      this.loading = true
    },
    async cancelEvent() {
      await dates.cancel(this.date.id, this.content, this.communicate)
      this.loading = false
      this.$bvToast.toast(`Booking cancelled for supplier`, {
        title: 'Booking cancelled',
        variant: 'primary',
      })

      this.$emit('cancelled')
    },
    close() {
      this.$emit('close', `calendar-date-${this.date.id}`)
    },
  },
}
</script>
