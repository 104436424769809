<template>
  <validation-observer v-slot="{ invalid }">
    <form @submit.prevent="formDataSubmit">
      <validation-provider v-if="hideCancel" name="Booking Date" v-slot="context">
        <b-form-group label="Pick a booking date...">
          <b-row>
            <b-col>
              <b-form-datepicker
                v-model="booking_date"
                locale="nb-NO"
                :start-weekday="1"
                :min="new Date()"
                :state="getValidationState(context)"
                v-bind="context.ariaInput"
                :disabled="$cannot('supplier.manage')"
              />

              <b-form-invalid-feedback v-bind="context.ariaMsg">
                {{ context.errors[0] }}
              </b-form-invalid-feedback>
            </b-col>
            <b-col cols="auto">
              <b-button variant="primary" @click="submitDate" :disabled="showAddEvent">
                Add Event
              </b-button>
            </b-col>
          </b-row>
        </b-form-group>
        <hr />
      </validation-provider>

      <validation-provider name="Number of Available Spots" v-slot="context" rules="required">
        <b-form-group
          label="Number of Available Spots"
          label-for="available-spots"
          :description="
            `${participants} ${participants === 1 ? 'spot' : 'spots'} will be valid for ${variation.participants * participants} participants (${variation.participants} participants per spot)`
          "
        >
          <b-form-input
            v-model="participants"
            type="number"
            placeholder="e.g 10"
            :min="1"
            :state="getValidationState(context)"
            v-bind="context.ariaInput"
            :disabled="$cannot('supplier.manage')"
          />

          <b-form-invalid-feedback v-bind="context.ariaMsg">
            {{ context.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <b-row>
        <b-col>
          <validation-provider name="Start Time" v-slot="context" rules="required">
            <b-form-group label="Start Time" label-for="input-1">
              <b-form-input
                v-model="starts_at"
                locale="en"
                type="time"
                :state="getValidationState(context)"
                v-bind="context.ariaInput"
                :disabled="$cannot('supplier.manage')"
              />

              <b-form-invalid-feedback v-bind="context.ariaMsg">
                {{ context.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <validation-provider name="End Time" v-slot="context" rules="required">
            <b-form-group label="End Time" label-for="input-1">
              <b-form-input
                v-model="ends_at"
                locale="en"
                type="time"
                :state="getValidationState(context)"
                v-bind="context.ariaInput"
                :disabled="$cannot('supplier.manage')"
              />

              <b-form-invalid-feedback v-bind="context.ariaMsg">
                {{ context.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>

      <validation-provider name="No of Days" v-slot="context" rules="required">
        <b-form-group label="No of Days">
          <b-form-input
            v-model="duration_days"
            type="number"
            placeholder="e.g 10"
            :state="getValidationState(context)"
            v-bind="context.ariaInput"
            :disabled="$cannot('supplier.manage')"
          />

          <b-form-invalid-feedback v-bind="context.ariaMsg">
            {{ context.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <hr />

      <validation-provider name="Last bookable date" v-slot="context" rules="required">
        <b-form-group label="Last bookable date (days before event starts):" label-for="bookable-days">
          <b-form-input
            v-model="last_bookable_date"
            id="bookable-days"
            type="number"
            placeholder="e.g 1"
            :min="0"
            :state="getValidationState(context)"
            v-bind="context.ariaInput"
            :disabled="$cannot('supplier.manage')"
          />

          <b-form-invalid-feedback v-bind="context.ariaMsg">
            {{ context.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <hr />

      <b-row>
        <b-col>Please choose a color:</b-col>
        <b-col cols="auto">
          <v-swatches v-model="color"
                      :show-fallback="false"
                      shapes="circles"
                      show-border
                      popover-x="left"
                      :swatches="swatches"
          />
        </b-col>
      </b-row>

      <hr />

      <validation-provider name="Booking Info" v-slot="context">
        <b-form-group label="Booking Info" description="Booking information for product page (Markdown Supported)">
          <small v-if="context.errors.length" v-bind="context.ariaMsg" class="text-danger form-text">
            {{ context.errors[0] }}
          </small>
          <froala v-model="booking_info" />
        </b-form-group>
      </validation-provider>

      <validation-provider name="Booking Info (Email)" v-slot="context">
        <b-form-group label="Booking Info (Email)" description="Booking information sent to email (Markdown Supported)">
          <small v-if="context.errors.length" v-bind="context.ariaMsg" class="text-danger form-text">
            {{ context.errors[0] }}
          </small>
          <froala v-model="booking_info_email" :not-allow-more-rich="['insertImage']"/>
        </b-form-group>
      </validation-provider>

      <hr />

      <template v-if="shouldUpdate">
        <b-row class="mb-2">
          <b-col>
            <h4>Custom Fields</h4>
          </b-col>

          <b-col cols="auto">
            <b-button
              size="sm"
              variant="outline-primary"
              v-b-modal="`custom-fields-${variation.id}`"
              :disabled="$cannot('supplier.manage')"
            >
              <i class="fa fa-plus" />
              <span>Add</span>
            </b-button>

            <b-modal :id="`custom-fields-${variation.id}`" title="Add Custom Field" hide-footer v-slot="{ ok }">
              <suppliers-booking-settings-custom-field :variation="variation" @added="onCustomFieldAdded(ok)" />
            </b-modal>
          </b-col>
        </b-row>

        <b-table :fields="customFieldFields" :items="customFieldItems" no-border-collapse fixed small>
          <template v-slot:cell(is_default)="row">
            <b-badge :variant="row.item.is_default ? 'primary' : 'secondary'">
              {{ String(row.item.is_default).toUpperCase() }}
            </b-badge>
          </template>

          <template v-slot:cell(options)="row">
            <b-button-group v-if="!row.item.is_default">
              <b-button size="sm" variant="link" @click="deleteField(row.item)" :disabled="$cannot('supplier.manage')">
                Delete
              </b-button>
            </b-button-group>
          </template>
        </b-table>
        <hr />
      </template>

      <b-row align-h="end">
        <b-col cols="auto">
          <b-button
            v-if="!hideCancel"
            variant="secondary"
            class="mr-2"
            @click="$emit('cancel')"
            :disabled="$cannot('supplier.manage')"
          >
            Cancel
          </b-button>
          <b-button variant="primary" type="submit" :disabled="invalid || $cannot('supplier.manage')">
            {{ hideCancel ? 'Update' : 'Submit' }}
          </b-button>
        </b-col>
      </b-row>
    </form>
  </validation-observer>
</template>

<script>
import VSwatches from 'vue-swatches'
import { booking } from '@/api'
import { getValidationState } from './page-helpers'
import 'vue-swatches/dist/vue-swatches.css'
import SuppliersBookingSettingsCustomField from './SuppliersBookingSettingsCustomField.vue'
import Froala from '@/components/Froala.vue'

export default {
  name: 'SuppliersBookingSettingsForm',
  inject: ['$cannot'],
  components: {
    VSwatches,
    SuppliersBookingSettingsCustomField,
    Froala,
  },
  props: { variation: Object, hideCancel: { type: Boolean, required: false } },
  data() {
    return {
      participants: this.variation.template?.participants ?? 1,
      starts_at: this.variation.template?.starts_at ?? '',
      ends_at: this.variation.template?.ends_at ?? '',
      duration_days: this.variation.template?.duration_days ?? 1,
      last_bookable_date: this.variation.template?.last_bookable_date ?? 1,
      booking_info: this.variation.booking_info,
      booking_info_email: this.variation?.template?.booking_info_email,
      color: this.variation.template?.color ?? '#00C27C',
      booking_date: '',
      swatches: [
        '#7f21f3', '#f32194', '#212bf3', '#1e212b',
        '#f37f21', '#94f321', '#e821f3', '#2196f3',
        '#f3e821', '#235b21', '#ffc0ff', '#21f3e8',
      ]
    }
  },
  computed: {
    shouldUpdate() {
      return Boolean(this.variation.template)
    },
     showAddEvent() {
        if (this.participants != this.variation.template?.participants) {
            return true;
        }

         if (this.starts_at != this.variation.template?.starts_at) {
             return true;
         }

         if (this.ends_at != this.variation.template?.ends_at) {
             return true;
         }

         if (this.duration_days != this.variation.template?.duration_days) {
             return true;
         }

         if (this.last_bookable_date != this.variation.template?.last_bookable_date) {
             return true;
         }

         if ((this.booking_info || null) != this.variation.template?.booking_info) {
             return true;
         }

         if ((this.booking_info_email || null) != this.variation.template?.booking_info_email) {
             return true;
         }

         if (this.color != this.variation.template?.color) {
             return true;
         }

        return false;
     },
    customFieldFields() {
      return [
        { key: 'field_label', label: 'Label' },
        { key: 'field_type', label: 'Type' },
        { key: 'is_default', label: 'Default' },
        { key: 'options', label: '' },
      ]
    },
    customFieldItems() {
      return this.variation.template.fields
    },
    templateData() {
      return {
        participants: this.participants,
        starts_at: this.starts_at,
        ends_at: this.ends_at,
        duration_days: this.duration_days,
        last_bookable_date: this.last_bookable_date,
        booking_info: this.booking_info,
        booking_info_email: this.booking_info_email,
        color: this.color,
      }
    },
  },
  methods: {
    getValidationState,
    async formDataSubmit() {
      if (this.shouldUpdate) {
        await booking.template.update(this.variation.template.id, this.templateData)
        this.$emit('updated')
      }

      await booking.template.create(this.variation.id, this.templateData)
      this.$emit('created')
    },
    async submitDate() {
      const date = await booking.dates.create(this.variation.id, {
        booking_template_id: this.variation.template.id,
        date: this.booking_date,
        confirmed: true,
      })

      this.$emit('date-submitted', { date })
    },
    async deleteField(item) {
      await booking.field.delete(item.id)

      this.$emit('custom-field-removed', { field: { id: item.id } })
    },
    onCustomFieldAdded(done) {
      this.$emit('custom-field-added')
      done()
    },
  },
}
</script>
