<template>
    <div>
        <div class="container">
            <div class="row">
                <div class="col-sm">
                    <h4>General Info</h4>
                </div>
                <div class="col-sm" v-if="can_edit">
                    <button class="btn btn-primary float-right text-white" @click="toggleForm">
                        <span v-if="updateDate">Exit edit form</span>
                        <span v-else>Edit</span>
                    </button>
                </div>
            </div>
        </div>

        <div v-if="can_edit && updateDate">
            <validation-observer>
                <form @submit.prevent="update">
                    <validation-provider name="Participants" v-slot="context" rules="required">
                        <b-form-group label="Participants">
                            <b-form-input
                                v-model="participants"
                                :state="getValidationState(context)"
                                v-bind="context.ariaInput"
                                type="number"
                            />

                            <b-form-invalid-feedback v-bind="context.ariaMsg">
                                {{ context.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider name="Starts" v-slot="context" rules="required">
                        <b-form-group label="Starts">
                            <b-form-datepicker
                                locale="nb-NO"
                                :start-weekday="1"
                                v-model="starts_at"
                                :state="getValidationState(context)"
                                v-bind="context.ariaInput"
                                :min="new Date()"
                            />

                            <b-form-invalid-feedback v-bind="context.ariaMsg">
                                {{ context.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider name="Start Time" v-slot="context" rules="required">
                        <b-form-group label="Start Time" label-for="input-1">
                            <b-form-input
                                v-model="start_time"
                                locale="nb-NO"
                                type="time"
                                :state="getValidationState(context)"
                                v-bind="context.ariaInput"
                            />
                            <b-form-invalid-feedback v-bind="context.ariaMsg">
                                {{ context.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider name="Ends" v-slot="context" rules="required">
                        <b-form-group label="Ends">
                            <b-form-datepicker
                                locale="nb-NO"
                                :start-weekday="1"
                                v-model="ends_at"
                                :state="getValidationState(context)"
                                v-bind="context.ariaInput"
                                :min="new Date()"
                            />

                            <b-form-invalid-feedback v-bind="context.ariaMsg">
                                {{ context.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider name="End Time" v-slot="context" rules="required">
                        <b-form-group label="End Time" label-for="input-2">
                            <b-form-input
                                v-model="end_time"
                                type="time"
                                locale="nb-NO"
                                :state="getValidationState(context)"
                                v-bind="context.ariaInput"
                            />

                            <b-form-invalid-feedback v-bind="context.ariaMsg">
                                {{ context.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider name="Last bookable date" v-slot="context" rules="required">
                        <b-form-group label="Last bookable date">
                            <b-form-datepicker
                                locale="nb-NO"
                                :start-weekday="1"
                                v-model="last_bookable_date"
                                :state="getValidationState(context)"
                                v-bind="context.ariaInput"
                                :min="new Date()"
                            />

                            <b-form-invalid-feedback v-bind="context.ariaMsg">
                                {{ context.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <hr/>

                    <validation-provider name="Booking Info" v-slot="context">
                        <b-form-group label="Booking Info" description="Booking information for product page (Markdown Supported)">
                            <small v-if="context.errors.length" v-bind="context.ariaMsg" class="text-danger form-text">
                                {{ context.errors[0] }}
                            </small>
                            <froala v-model="booking_info" />
                        </b-form-group>
                    </validation-provider>

                    <validation-provider name="Booking Info (Email)" v-slot="context">
                        <b-form-group label="Booking Info (Email)" description="Booking information sent to email (Markdown Supported)">
                            <small v-if="context.errors.length" v-bind="context.ariaMsg" class="text-danger form-text">
                                {{ context.errors[0] }}
                            </small>
                            <froala v-model="booking_info_email" :not-allow-more-rich="['insertImage']"/>
                        </b-form-group>
                    </validation-provider>

                    <div v-if="errors.ends_at" class="text-red">
                        {{ errors?.ends_at[0] }}
                    </div>

                    <div class="clearfix">
                        <b-button variant="primary" class="pull-right"
                                  @click="update">
                            Update
                        </b-button>
                    </div>
                </form>
            </validation-observer>
        </div>
        <div v-else>
            <ul>
                <li>Spots: <strong>{{ date.participants }}</strong></li>
                <li>Product Variation Participants: <strong>{{ date.product_variation_participants }}</strong></li>
                <li>Total Participants: <strong>{{ date.participants * date.product_variation_participants }}</strong></li>
                <li>Starts: <strong>{{ date.starts_at }}</strong></li>
                <li>Ends: <strong>{{ date.ends_at }}</strong></li>
                <li>Last bookable date: <strong>{{ date.last_bookable_date }}</strong></li>
                <li v-if="!date.cancelled && new Date()">
                    Booking info: <br>
                    <strong v-html="booking_info"></strong>
                </li>
                <li>
                    Booking info email: <br>
                    <strong v-html="booking_info_email"></strong>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs'
import {booking} from '@/api'
import {getValidationState} from './page-helpers'
import Froala from "@/components/Froala.vue";

export default {
    components: {Froala},
    props: {date: Object},
    data() {
        return {
            participants: this.date.participants,
            starts_at: this.date.starts_at,
            ends_at: this.date.ends_at,
            start_time: null,
            end_time: null,
            booking_info: this.date.booking_info,
            booking_info_email: this.date.booking_info_email,
            can_edit: this.date.can_edit,
            last_bookable_date: this.date.last_bookable_date,
            updateDate: false,
            errors: '',
        }
    },
    mounted() {
        this.start_time = dayjs(this.starts_at).format('HH:mm')
        this.end_time = dayjs(this.ends_at).format('HH:mm')
        this.last_bookable_date = dayjs(this.date.last_bookable_date).format('YYYY-MM-DD HH:mm')
    },
    methods: {
        getValidationState,
        async update() {
            try {
                const date = await booking.dates.update(this.date.id, {
                    participants: this.participants,
                    starts_at: dayjs(this.starts_at).format('YYYY-MM-DD'),
                    ends_at: dayjs(this.ends_at).format('YYYY-MM-DD'),
                    start_time: this.start_time,
                    end_time: this.end_time,
                    last_bookable_date: this.last_bookable_date,
                    booking_info: this.booking_info,
                    booking_info_email: this.booking_info_email,
                })

                this.$bvToast.toast(`Settings updated`, {
                    title: 'Booking date updated',
                    variant: 'primary',
                })

                this.updateDate = false
                this.errors = ''
                this.$emit('updated', {date})

            } catch (e) {
                this.errors = e.errors;
            }
        },
        toggleForm() {
            this.updateDate = !this.updateDate
        }
    },
}
</script>
