<template>
  <validation-observer v-slot="{ invalid }">
    <form @submit.prevent="onSubmit">
      <validation-provider name="Field Type" v-slot="context" rules="required">
        <b-form-group label="Field Type">
          <b-form-select
            v-model="field_type"
            :options="inputFields"
            :state="getValidationState(context)"
            v-bind="context.ariaInput"
          />

          <b-form-invalid-feedback v-bind="context.ariaMsg">
            {{ context.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <validation-provider name="Label" v-slot="context" rules="required">
        <b-form-group label="Label">
          <b-form-input v-model="field_label" :state="getValidationState(context)" v-bind="context.ariaInput" />

          <b-form-invalid-feedback v-bind="context.ariaMsg">
            {{ context.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <validation-provider name="Info" v-slot="context" rules="required">
        <b-form-group label="Info">
          <b-form-input v-model="info" :state="getValidationState(context)" v-bind="context.ariaInput" />

          <b-form-invalid-feedback v-bind="context.ariaMsg">
            {{ context.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <validation-provider name="Placeholder" v-slot="context" rules="required">
        <b-form-group label="Placeholder">
          <b-form-input v-model="placeholder" :state="getValidationState(context)" v-bind="context.ariaInput" />

          <b-form-invalid-feedback v-bind="context.ariaMsg">
            {{ context.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <b-form-checkbox v-model="required" name="check-button" switch>
        Required?
      </b-form-checkbox>

      <hr />

      <b-button :disabled="invalid" variant="primary" type="submit">
        Submit
      </b-button>
    </form>
  </validation-observer>
</template>

<script>
import { booking } from '@/api'
import { getValidationState } from './page-helpers'

export default {
  name: 'SuppliersBookingSettingsCustomField',
  inject: ['$cannot'],
  props: { variation: Object },
  data() {
    return {
      field_type: 'input',
      field_label: '',
      info: '',
      required: false,
      placeholder: '',
    }
  },
  computed: {
    inputFields() {
      return [
        { value: 'input', text: 'Input' },
        { value: 'textfield', text: 'Text Field' },
        { value: 'terms', text: 'Terms' },
        { value: 'info', text: 'Information' },
        { value: 'email', text: 'E-mail' },
        { value: 'phone', text: 'Phone Number' },
      ]
    },
  },
  methods: {
    getValidationState,
    async onSubmit() {
      const field = await booking.field.create(this.variation.template.id, {
        field_type: this.field_type,
        field_label: this.field_label,
        info: this.info,
        required: this.required,
        placeholder: this.placeholder,
      })

      this.$emit('added', { field: field.data })
    },
  },
}
</script>
